import React, { useEffect, useState } from 'react'

import { Container, Content, TitleStep, ContentStepBox, Row, StepBox, NumberStep, StepName } from './styles'

const Check = () => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.81686 1.17363C8.71005 1.06251 8.56465 1 8.41297 1C8.26129 1 8.11589 1.06251 8.00907 1.17363L3.77101 5.5526L1.99045 3.70944C1.84164 3.5574 1.62508 3.49969 1.42353 3.55837C1.22198 3.61704 1.06659 3.78303 1.01673 3.99291C0.966874 4.20279 1.03026 4.42411 1.18266 4.57232L3.36711 6.82637C3.47393 6.93749 3.61933 7 3.77101 7C3.92269 7 4.06809 6.93749 4.1749 6.82637L8.81686 2.03651C8.9336 1.92538 9 1.76896 9 1.60507C9 1.44118 8.9336 1.28476 8.81686 1.17363Z"
        fill="white"
        stroke="white"
      />
    </svg>
  )
}

const Steps = ({ steps, currentStep }: any) => {
  const [stepState, setStepState] = useState([])

  useEffect(() => {
    /*eslint-disable */

    let createSteps = steps.map((step, idx) => ({
      description: step.label,
      component: step.component,
      selected: idx === currentStep - 1,
      prev: idx <= currentStep - 2,
    }))
    setStepState(createSteps)
  }, [steps, currentStep])
  /* eslint-disable */
  return (
    <Container>
      <Content>
        <TitleStep>ETAPAS</TitleStep>
        <ContentStepBox>
          <Row></Row>
          {stepState.map(({ selected, description, prev }, idx) => (
            <StepBox key={idx}>
              <NumberStep className={`disabled + ${(selected === true && 'active') || (prev === true && 'prev')}`}>
                {prev ? <Check /> : idx + 1}
              </NumberStep>
              <StepName>{description}</StepName>
            </StepBox>
          ))}
        </ContentStepBox>
      </Content>
    </Container>
  )
}

export default Steps
